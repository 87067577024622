import VueRecaptcha from 'vue-recaptcha';
export default {
	name: "ContactUs",
	created () {
		this.$nextTick(function () {
			this.ariseCombobox('#comboboxSnedType')
		});
	},
	mounted () {

	},
	data () {
		return {
			checkCaptch: false,
			Disabled: true,
			type: 'Trade Index Structured Warrants & Win',
			email: '',
			phone: '',
			subject: '',
			comment: '',
			code: '',
			ModalText: '',
			localStorageL: localStorage.getItem('lang')
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		onVerify: function (response) {
			this.code = response;
			if (response == "") {
				this.checkCaptch = false;
			} else {
				this.checkCaptch = true;
				if (this.checkCaptch) {
					this.Disabled = false
				} else {
					this.Disabled = true
				}
			}
		},
		onExpired: function () {
			this.checkCaptch = false;
			this.Disabled = true;
		},
		ContactUs: function () {
			if (this.type && this.email && this.phone && this.comment && this.code) {
				this.Disabled = true;
				$.ajax({
					type: "GET",
					url: this.$$.mibbmail + "ContactUs",
					data: { type: this.type, email: this.email, phone: this.phone, subject: this.subject, comment: this.comment, code: this.code },
					dataType: "json",
					success: (data) => {
						if (data.status === "SUCCESS") {
							this.ModalText = 'Thank you for your feedback.'
							// 模态框出现
							$('#ContactUsModal').modal('show')
							setTimeout(() => {
								this.$router.go(0);
							}, 1000);
						}
					},
					error: function (error) {
						console.log(error);
					}
				});
			} else {
				this.Disabled = false
			}
		},
		getType: function (num) {
			this.type = num
		},
		inputInfo: function (v, e) {
			this[v] = e.target.value
		},
		// 出现combobox
		ariseCombobox: function (id) {
			let _this = this
			this.$$.jqcombobox(id)
			$(id).combobox({
				select: function (event, ui) {
					_this.type = this.value
				}
			});
		},
	},
	components: { VueRecaptcha }
};